
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class BottomBar extends Vue {
    route = '#user'

    created() {
        let bar: HTMLElement | null = document.getElementById('bottom-bar')

        let element: HTMLElement | null = document.getElementById('userLink')
        if (bar && element) {
            bar.style.left = `${element.getBoundingClientRect().left - 17}px`
        }
    }

    mounted() {
        if (this.$root) {
        }

        let bar: HTMLElement | null = document.getElementById('bottom-bar')

        let element: HTMLElement | null = document.getElementById('userLink')
        if (bar && element) {
            bar.style.left = `${element.getBoundingClientRect().left - 17}px`
        }
    }
    changeRouteScroll(route: string) {
        let bar: HTMLElement | null = document.getElementById('bottom-bar')

        if (route !== this.route) {
            this.route = route
            if (route === '#user') {
                let element: HTMLElement | null =
                    document.getElementById('userLink')
                if (bar && element) {
                    bar.style.left = `${
                        element.getBoundingClientRect().left - 17
                    }px`
                }
            } else if (route === '#code') {
                let element: HTMLElement | null =
                    document.getElementById('codeLink')

                if (bar && element) {
                    bar.style.left = `${
                        element.getBoundingClientRect().left - 17
                    }px`
                }
            } else {
                let element: HTMLElement | null =
                    document.getElementById('dataLink')

                if (bar && element) {
                    bar.style.left = `${
                        element.getBoundingClientRect().left - 17
                    }px`
                }
            }
        }
    }

    changeRoute(route: string): void {
        this.route = route
        window.location.href = route

        let bar: HTMLElement | null = document.getElementById('bottom-bar')

        if (route === '#user') {
            let element: HTMLElement | null =
                document.getElementById('userLink')
            if (bar && element) {
                bar.style.left = `${
                    element.getBoundingClientRect().left - 17
                }px`
            }
        } else if (route === '#code') {
            let element: HTMLElement | null =
                document.getElementById('codeLink')

            if (bar && element) {
                bar.style.left = `${
                    element.getBoundingClientRect().left - 17
                }px`
            }
        } else {
            let element: HTMLElement | null =
                document.getElementById('dataLink')

            if (bar && element) {
                bar.style.left = `${
                    element.getBoundingClientRect().left - 17
                }px`
            }
        }
    }
}
