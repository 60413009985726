
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        codes: {
            type: Array,
        },
    },
})
export default class Select extends Vue {
    activeSelect = true
}
