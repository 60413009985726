
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        linkGit: {
            type: String,
            default: null,
        },
        link: {
            type: String,
            default: null,
        },
    },
})
export default class Projects extends Vue {}
