
import { Options, Vue } from 'vue-class-component'
import BottomBar from '../components/BottomBar.vue'
import Select from '../components/Select.vue'
import Project from '../components/Projects.vue'
import Switch from '../components/Switch.vue'

@Options({
    components: { BottomBar, Select, Project, Switch },
})
export default class Home extends Vue {
    changeRouteScroll(route: string) {
        ;(this.$refs.buttomBar as any).changeRouteScroll(route)
    }
}
