
import { Options, Vue } from 'vue-class-component'

@Options({
    watch: {
        value: (value) => {
            if (value) {
                console.log('Dark')
                localStorage.setItem('Color', 'dark')
                const html = document.querySelector('html')
                if (html) html.classList.toggle('dark-mode')
            } else {
                localStorage.setItem('Color', 'light')
                const html = document.querySelector('html')
                if (html) html.classList.toggle('dark-mode')
            }
        },
    },
})
export default class Switch extends Vue {
    value = true

    mounted() {
        if (localStorage.getItem('Color') === 'light') {
            this.value = false
        } else {
            this.value = true
        }
    }

    changeColor() {
        this.value = !this.value
    }
}
